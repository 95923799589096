import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Skeleton,
    Modal, 
    ModalOverlay, 
    ModalContent, 
    FormHelperText,
    ModalHeader, 
    Stack,
    Input,
    ModalBody, 
    ModalCloseButton,
    FormControl
} from '@chakra-ui/react';
import { useInterval } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsPlusSquareDotted } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import AITextBox from '../components/AITextBox';
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function AITexts() {
  const user = useRecoilValue(userAtom);
  const [texts, setTexts] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [clickable, setClickable] = useState(true)
  const [showModal, setShowModal] = useState(false);
  const [pdfFile, setPDF] = useState(null);


  const handlePDFChange = (e) => {
    setPDF(e.target.files[0]);
  };


  const getData = async () => {
    await axios.get('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/ai-texts/get/"+user.email).then(res => {
      setTexts(res.data.texts);
      setFileUploaded(res.data.fileUploaded);
  
      if (!res.data.fileUploaded) {
        setShowModal(true);
      }
      
      setDataLoaded(true);
    }).catch(err => {
      console.log(err);
    });
  }
  


  useEffect(() => {
    getData();
  }, []);
  

  const getTexts = () => {
    if(fileUploaded){
      axios.get('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/ai-texts/get/"+user.email).then(res => {
      
      let oldTexts = texts;
      let newTexts = res.data.texts;
      console.log(clickable)
      if(oldTexts.length != newTexts.length){
        setTexts(res.data.texts);
        setClickable(true)
      }

      setTexts(res.data.texts);

  }).catch(err => {
    console.log(err);
  });
    }
    

  }

  const generateAITexts = () => {
    if(fileUploaded){
      setClickable(false);
      axios.get('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/ai-text/create/"+user.email).then(res => {
    }).catch(err => {
      console.log(err);
    });
  }else{
    setShowModal(true)
  }
  }

  useInterval(getTexts, 7500)

  const handleSubmit =  async (event, price_id) => {
        setIsLoading(true);

        const reader = new FileReader();
        reader.readAsArrayBuffer(pdfFile);
        reader.onloadend = async () => {
            const uint8Array = new Uint8Array(reader.result);
            const pdf = await pdfjs.getDocument(uint8Array).promise;
        
            let textContent = "";
            for (let i = 1; i <= pdf.numPages; i++) {
                const page = await pdf.getPage(i);
                const pageTextContent = await page.getTextContent();
                textContent += pageTextContent.items
                .map((item) => item.str)
                .join("");
            }

            const data = {
                    pdf: textContent,   
                    accountID: user.accountID,
                }
            const res = axios.post('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + '/api/add-pdf', data).then(
                res => {
                console.log(res.status)
                if(res.status == 200){
                    console.log('created user.')
                    window.location = window.location;
                }else if(res.status == 201){
                    console.log('email already in use')
                    alert('Sorry, there is already an account witht this email.')
                }else if(res.status == 500){
                    console.log('server error')
                    alert('Sorry, there was a server error. Please try again or contact support.')

                }
                setIsLoading(false);
                }
            )
        };
    }


  return (
    <Flex w="100%">

      <Sidebar />
      <Box p={4} w='full' pb={0}>

        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
            <Box>
    <Box w='100%' pt={6} pb={6} > 
        <Flex  m='0 auto' bg='white' borderRadius={'8px'} border={'solid 3px #eeecea '}>
            <form style={{ width:'100%', padding:'2rem' }}>
                <Stack spacing={4}>
                    <Text margin='0 auto' mb='2vh' color="#000" textAlign='center' fontSize="2rem" fontWeight="900">Lets get started.</Text>
                    
                    <Text fontWeight={600} fontSize={'1.3rem'} color='#000'>In order for the AI to create text messages for you, we need to tell it what you're selling. </Text>
                    <Text fontWeight={600} fontSize={'1.3rem'} color='#000'>Upload a PDF of your business's menu/services.</Text>
                    <Text fontWeight={600} fontSize={'1rem'} color='gray'>*If your business's menu/services are only available online, use <a href='https://www.web2pdfconvert.com/' target="_blank" rel="noopener noreferrer" style={{'textDecoration':'underline'}}>this</a> tool to get a PDF version.</Text>
                    <Flex flex={1}>
                     
                        <Flex flex={1} border="6px dashed #eeecea" borderRadius='6px'  textAlign={'center'} direction={'column'} p={6}>
                            <Text w='100%' fontWeight={600} fontSize={'1.6rem'} color='#000'>PDF</Text>
                            <FormControl >
                                <Input accept="application/pdf" type="file" id="pdf" onChange={handlePDFChange} />
                                <FormHelperText>Select a PDF</FormHelperText>
                            </FormControl>

                        </Flex>

                    </Flex>
                    <Button isLoading={isLoading} onClick={() => handleSubmit()} my={6} bg='#5A7BFF' color='white' _hover={{ bg: '#5A7BFF' }} fontWeight={700}>Process PDF</Button>

                </Stack>
            </form>
        </Flex>
    </Box>
    </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

          <Box w='100%'>
              <Text textAlign={'center'} color='#000' fontSize='2rem' fontWeight='800'>Artificial-Intelligence Texts</Text>
              <Text textAlign={'center'} color='gray.500' fontSize='1rem' fontWeight='500'>
                  Use AI Helper to create SMS templates to send out on your next campaign
              </Text>
              <Text textAlign={'center'} color='gray.500' fontSize='1rem' fontWeight='500'>
                  AI Helper is designed to create templates that will get you repeat customers and increase your revenue
              </Text>

              <Box>
                  <Flex direction={'column'} m={1} mb='8vh'>
                    
                      {/* <Skeleton isLoaded={dataLoaded}>
                        
                      </Skeleton> */}

                      <Flex direction={'row'} w={'100%'}  flex={1} wrap='wrap'>
                        {texts.map(elem => {
                          return (
                          <Skeleton isLoaded={dataLoaded}>
                            <Flex flex={1}  width={'fit-content'}>
                              <AITextBox body={elem.body} saved={elem.saved} id={elem.id}/>                        
                            </Flex>
                          </Skeleton>
                          )
                        })}
                        <Skeleton isLoaded={clickable}> 
                          <Flex width={'20vw'} height={'50vh'} >
                            <Box onClick={() => generateAITexts()} cursor='pointer' m={6} width={'100%'} border={'3px dashed #000'} borderRadius="8px" _hover={{boxShadow:"5px 5px 10px 10px rgba(133,165,234,0.5)"}} boxShadow={"5px 5px 10px 10px rgba(133,165,234,0.2)"} bg="rgba(133,165,234,0.8)">
                              <Flex verticalAlign={'middle'} direction={'column'} color='#000' margin='0 auto' justifyContent={'center'}>
                                <Text mt='30%' textAlign={'center'} fontSize='2rem' fontWeight='800'>Create More</Text>
                                  <BsPlusSquareDotted size={'5rem'} style={{margin:'0 auto'}} />
                              </Flex>
                            </Box>   
                          </Flex>
                        </Skeleton>

                        
                      </Flex>
                  </Flex>
              </Box>
          </Box>
      </Box>
    </Flex>
  )
}