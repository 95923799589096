import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
} from '@chakra-ui/react';

import QRChart from '../components/QRChart';
import { AiFillPrinter } from "react-icons/ai";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import { useLocation } from 'react-router-dom';

export default function QRPage() {

    const [qrData, setQrData] = useState({})
    const [dates, setDates] = useState([])
    const [quantities, setQuantities] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const from = params.get('from');

    const user = useRecoilValue(userAtom);
    const mql = window.matchMedia('(max-width: 480px)');

    let mobileView = mql.matches;
        
    const downloadImg = () => {
        saveAs(`http://api.qrserver.com/v1/create-qr-code/?data=https://moloboost.ca/datacap/?acctID=${user.aaccountID}&size=300x300`, "RetainlySMS-QR-Review.jpg")
    }

    const getContact = () => {

        axios.get('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + '/api/qr-analytics/get/'+user.email).then(res => {

            setQrData(res.data.data);
            setDates(res.data.dates);
            setQuantities(res.data.quantities);
            setDataLoaded(true);
        }).catch(err => {
            console.log(err);
        });
    }
        

    useEffect(() =>{
        getContact();
        if(from == 'login' && mobileView){
            alert('Looks like your on a mobile device. Things may look off, this site is meant to be viewed on a larger screen')
        }
    }, [from]) ;


  return (
    <Flex w="100%">
      <Sidebar />
      <Box p={4} w='full' pb={0}>

        <Box w='100%'>
            <Text textAlign={'center'} color='#000' fontSize='2rem' fontWeight='800'>QR Code/Reviews</Text>
            <Text textAlign={'center'} color='gray.500' fontSize='1rem' fontWeight='500'>
                Boost your online rank with reviews-- get discovered by more people online.
            </Text>

                <Box w='100%' >
                    <Flex>
                        <Flex direction='column' flex={1}>
                            <Box p={1} m={6} borderRadius="8px" boxShadow={"5px 5px 10px 10px rgba(0,0,0,0.05)"} >
                                <Image m='0 auto' src={`http://api.qrserver.com/v1/create-qr-code/?data=https://moloboost.ca/review/${user.accountID}&size=300x300`} />
                                
                            </Box>   
                            <Flex textAlign={'left'} direction='column'>
                                <Box margin='0 auto'>
                                    <Button onClick={() => downloadImg()} bg={'#000'} color='white' textAlign={'center'} w={'60%'}><AiFillPrinter/></Button>
                                    <Skeleton isLoaded={dataLoaded}>
                                        <Text color='#000' fontSize='1.2rem' fontWeight='600'>{qrData.today} scans today</Text>
                                        <Text color='#000' fontSize='1.2rem' fontWeight='600'>{qrData.last_7_days} scans last 7 days</Text>
                                        <Text color='#000' fontSize='1.2rem' fontWeight='600'>{qrData.last_28_days} scans last 28 days</Text>
                                    </Skeleton>
                                </Box>
                            </Flex>
                        </Flex>

                        <Flex flex={2}>
                            <Skeleton isLoaded={dataLoaded}>
                                <QRChart dates={dates} quantities={quantities}/>
                            </Skeleton>
                        </Flex>
                    </Flex>
                </Box>
        </Box>
      </Box>
    </Flex>
  )
}