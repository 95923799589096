import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Textarea,
    InputLeftAddon,
    InputGroup,
    Circle,
    Input,
} from '@chakra-ui/react';
import { BiPlusMedical } from 'react-icons/bi';
import { FiUser } from 'react-icons/fi';
import { useRef, useState } from 'react';
import axios from 'axios';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';


export default function ContactAdd() {
    const nameRef = useRef();
    const phoneRef = useRef();
    const notesRef = useRef();
    const history = useNavigate();
    const user = useRecoilValue(userAtom);
    const [isValid, setIsValid] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState("");

    const [value, setValue] = useState('First Last')
    const [isDataLoading, setDataLoading] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(false);
    const [dupNumber, setDupNumber] = useState(false);


    const handleChange = (event) => {
        const inputValue = event.target.value.replace(/[^\d]/g, ""); // remove non-digits
        setPhoneNumber(inputValue);
        setIsValid(inputValue.length === 10 || inputValue.length === 0); // check if input is a valid US phone number
    };
    const formattedValue = (value) => {
        const regex = /^(\d{0,3})?(\d{0,3})?(\d{0,4})?$/;
        const match = regex.exec(value);
        if (match) {
            const areaCode = match[1] ? `(${match[1]}) ` : "";
            const firstPart = match[2];
            const secondPart = match[3];
            if (value.length <= 3) {
                return value;
            } else if (value.length <= 6) {
                return `${areaCode}${firstPart}`;
            } else {
                return `${areaCode}${firstPart}-${secondPart}`;
            }
        } else {
            return value;
        }
    };
    

    const handleInputChange = (e) => {
        let inputValue = e.target.value
        setValue(inputValue)
    }

    const saveContact = () => {
        setDataLoading(true);
        setDupNumber(false);

        const phoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?\d{3}\d{4}$/;
        if (!phoneNumberRegex.test(phoneNumber)) {
            setDataLoading(false);
            setInvalidPhone(true);
            return;
        }

        if(nameRef.current.value.length ==0){
            alert('Please enter a valid name.');
            setDataLoading(false);
            return;
        }
            const year = new Date().getFullYear();
            const month = new Date().getMonth()+1;
            const day = new Date().getDate();

            const dateString = month + '/' + day + '/' + year;

            axios.post('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/contacts/add/"+user.email, {
                dateAdded: dateString,
                name: nameRef.current.value.trim(),
                phone: phoneNumber,
                notes: notesRef.current.value.trim(),
            }).then(res => {
                setDataLoading(false);
                history('/dashboard/contacts')
            }).catch(err => {
                console.log(err)
                setDupNumber(true);
                setDataLoading(false);
            });
            setDataLoading(false);
            setInvalidPhone(false);
    }



  return (
    <Flex w="100%">
      <Sidebar />
      <Box p={4} w='full' pb={0}>

        <Box w='100%'>
            <Text textAlign={'center'} color='#000' fontSize='2rem' fontWeight='800'>Add a Contact</Text>
            <Text textAlign={'center'} color='gray.500' fontSize='1rem' fontWeight='500'>
                Manually add a contact to your contact list.
            </Text>

            <Box>
                <Flex direction={'column'}>
                    <Flex justifyContent={'space-between'}>
                        <Box p={'4%'} m={'0 auto'} borderRadius="8px" boxShadow={"5px 5px 10px 10px rgba(0,0,0,0.05)"} pt={0}>
                            <Flex direction='column' p={4}>

                                <Circle size='5vw' m='0 auto' bg='gray.200'>
                                    <FiUser size='3vw' color='#000'/>
                                </Circle>
                                <Text textAlign={'center'} color='gray.500' fontSize='1rem' fontWeight='500'>
                                    {value}
                                </Text>

                                {invalidPhone && 
                                    <Box p={4} textAlign='center'>
                                    <Text color='red.300'>Uh-oh! Phone number is invalid.</Text>  
                                    </Box>
                                }
                                {dupNumber && 
                                    <Box p={4} textAlign='center'>
                                    <Text color='red.300'>Hmmm, this phone is already in this list.</Text>  
                                    </Box>
                                }
                                
                                
                                <Box mb={2}>
                                    <InputGroup>
                                        <InputLeftAddon>
                                            <FiUser/>
                                        </InputLeftAddon>
                                        <Input 
                                        ref={nameRef}
                                        onChange={handleInputChange}
                                        placeholder='Name'/>
                                    </InputGroup>
                                </Box>

                                <Box mb={2}>
                                <InputGroup>
                                    <InputLeftAddon children="+1" />
                                    <Input
                                        type="tel"
                                        placeholder="(123) 456-7890"
                                        value={formattedValue(phoneNumber)}
                                        onChange={handleChange}
                                        isInvalid={!isValid}
                                        maxLength={14} // restrict to 14 characters
                                    />
                                    </InputGroup>
                                </Box>

                                <Box mb={4}>
                                    <Textarea placeholder='Notes' ref={notesRef}/>
                                </Box> 
                                
                                <Box  m='0 auto'>
                                    <Button bg='#5A7BFF' color='#fff' _hover={{bg:'#5A7BFF'}} isLoading={isDataLoading} onClick={() => saveContact()}>
                                        <BiPlusMedical/>  
                                        Add Contact
                                    </Button>
                                </Box>
                            </Flex>
                        </Box>  
                    </Flex>
                </Flex>
            </Box>
        </Box>
      </Box>
    </Flex>
  )
}