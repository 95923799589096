import React, { useState } from 'react';
import Chart from 'react-apexcharts'

const ColumnChart = (props) => {
  console.log(props.stats);

     const series = [{
        data: [
          props.stats?.sent,
          props.stats?.delivered, 
          props.stats?.faild, 
          props.stats?.undelivered
        ]
      }];

    const options =
    {
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ['Sent', 'Delivered', 'Failed', 'Undelivered']
        }
      }
      

  return (
    <div className="column">
        <Chart options={options} series={series} type="bar" width="100%" />
      </div>
  )
}

export default ColumnChart;
