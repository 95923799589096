import SignUpComponent from "../components/SignUpComponent"
import { 
    Box, 
    Flex,
    Text,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Button,
    Input,
} from '@chakra-ui/react';
import SignUpHero from "../components/SignUpHero";

import { Link } from "react-router-dom";


const mql = window.matchMedia('(max-width: 480px)');

let mobileView = mql.matches;


const SignUp = () => {
  return (
    <Flex>
        {!mobileView?
        <Flex direction={'column'}>
            <Text margin='0 auto' mb='2vh' color="#000" textAlign='center' fontSize="2rem" fontWeight="900">RetainlySMS</Text>
            <Flex mb={2}>
                <Box flex={1} pl={6}>
                    <SignUpHero/>
                </Box>
                <Box flex={2}>  
                    <SignUpComponent/>
                </Box>
            </Flex>
            <Box textAlign={'center'}>
                <Link to='/login'>
                    <Text fontWeight='500' color='#000'>Already have an account? Sign in</Text>
                </Link>
            </Box>
        </Flex>
        :
        <Flex direction={'column'}>
            <Text margin='0 auto' mb='2vh' color="#000" textAlign='center' fontSize="2rem" fontWeight="900">RetainlySMS</Text>
            <Box flex={1}>
                <SignUpHero mobile={mobileView}/>
            </Box>
            <Box flex={1}>  
                <SignUpComponent mobile={mobileView}/>
            </Box>
            <Box textAlign={'center'}>
                <Link to='/login'>
                    <Text fontWeight='500' color='#000'>Already have an account? Sign in</Text>
                </Link>
            </Box>

        </Flex>
        }


    </Flex>
    
  )
}

export default SignUp
