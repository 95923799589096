import VerifyComponent from "../components/VerifyComponent"


const Verification = () => {
  return (
    <div>
      <VerifyComponent/>
    </div>
  )
}

export default Verification
