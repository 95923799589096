import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Textarea,
    InputLeftAddon,
    Skeleton,
    InputGroup,
    Circle,
    Input,
} from '@chakra-ui/react';
import { BiSave } from 'react-icons/bi';
import { FiUser } from 'react-icons/fi';
import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';


export default function ContactEdit() {
    const nameRef = useRef();
    const phoneRef = useRef();
    const notesRef = useRef();
    const history = useNavigate();
    const user = useRecoilValue(userAtom);


    const [value, setValue] = useState('First Last')
    const [isDataLoading, setDataLoading] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(false);
    const [dupNumber, setDupNumber] = useState(false);
    const [contactObj, setContactObj] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [contactID, setContactID] = useState(0);
    

    const handleInputChange = (e) => {
        let inputValue = e.target.value
        setValue(inputValue)
    }

    const saveContact = () => {
        setDataLoading(true);
        setDupNumber(false);

        const phoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?\d{3}\d{4}$/;
        if (!phoneNumberRegex.test(phoneRef.current.value)) {
            setDataLoading(false);
            setInvalidPhone(true);
            return;
        }

        if(nameRef.current.value.length ==0){
            alert('Please enter a valid name.');
            setDataLoading(false);
            return;
        }
        // '/api/contact/edit/<string:email>/<int:id>'
            axios.post('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/contact/edit/"+user.email+"/"+contactID, {
                name: nameRef.current.value.trim(),
                phone: phoneRef.current.value.trim(),
                notes: notesRef.current.value.trim(),
            }).then(res => {
                setDataLoading(false);
                history('/dashboard/contacts')
            }).catch(err => {
                console.log(err)
                setDupNumber(true);
                setDataLoading(false);
            });
            setDataLoading(false);
            setInvalidPhone(false);
    }

    const getContact = () => {
        var path = window.location.pathname;
        var parts = path.split('/');
        const id = parts.pop();
        setContactID(id);

        axios.get('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + '/api/contact/get/'+user.email+ '/'+id).then(res => {
            setContactObj(res.data.contact);
            setValue(res.data.contact.name)
            setDataLoaded(true);
        }).catch(err => {
            console.log(err);
        });
    }
        

    useEffect(() =>{
        getContact();
    }, []) ;

  return (
    <Flex w="100%">
      <Sidebar />
      <Box p={4} w='full' pb={0}>

        <Box w='100%'>
            <Text textAlign={'center'} color='#000' fontSize='2rem' fontWeight='800'>Edit a Contact</Text>
            <Text textAlign={'center'} color='gray.500' fontSize='1rem' fontWeight='500'>
                Manually edit a contact from your contact list.
            </Text>

            <Box>
                <Flex direction={'column'}>
                    <Flex justifyContent={'space-between'}>
                        <Skeleton isLoaded={dataLoaded} m={'0 auto'}>
                            <Box p={'4%'}  borderRadius="8px" boxShadow={"5px 5px 10px 10px rgba(0,0,0,0.05)"} pt={0}>
                                <Flex direction='column' p={4}>

                                    <Circle size='5vw' m='0 auto' bg='gray.200'>
                                        <FiUser size='3vw' color='#000'/>
                                    </Circle>
                                    <Text textAlign={'center'} color='gray.500' fontSize='1rem' fontWeight='500'>
                                        {value}
                                    </Text>

                                    {invalidPhone && 
                                        <Box p={4} textAlign='center'>
                                        <Text color='red.300'>Uh-oh! Phone number is invalid.</Text>  
                                        </Box>
                                    }
                                    {dupNumber && 
                                        <Box p={4} textAlign='center'>
                                        <Text color='red.300'>Something went wrong. Please try again.</Text>  
                                        </Box>
                                    }
                                    
                                    
                                    <Box mb={2}>
                                        <InputGroup>
                                            <InputLeftAddon>
                                                <FiUser/>
                                            </InputLeftAddon>
                                            <Input 
                                            ref={nameRef}
                                            onChange={handleInputChange}
                                            defaultValue={contactObj.name}
                                            placeholder='Name'/>
                                        </InputGroup>
                                    </Box>

                                    <Box mb={2}>
                                        <InputGroup>
                                            <InputLeftAddon children='+1' />
                                            <Input type='tel' placeholder='Phone' ref={phoneRef} defaultValue={contactObj.phone}/>
                                        </InputGroup>
                                    </Box>

                                    <Box mb={4}>
                                        <Textarea placeholder='Notes' ref={notesRef} defaultValue={contactObj.notes}/>
                                    </Box> 
                                    
                                    <Box  m='0 auto'>
                                        <Button bg='#5A7BFF' color='#fff' _hover={{bg:'#5A7BFF'}} isLoading={isDataLoading} onClick={() => saveContact()}>
                                            <BiSave/>  
                                            Save Changes
                                        </Button>
                                    </Box>
                                </Flex>
                            </Box>  
                        </Skeleton>
                    </Flex>
                </Flex>
            </Box>
        </Box>
      </Box>
    </Flex>
  )
}