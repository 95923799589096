import { useState, useEffect, useRef } from "react";
import { 
    FormControl, 
    FormLabel, 
    Input, 
    FormErrorMessage, 
    Stack, 
    InputGroup,
    InputRightAddon,
    Switch,
    Button, 
    ListItem,
    UnorderedList,
    Center, 
    Flex, 
    Text,
    Box,
    FormHelperText

} from '@chakra-ui/react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytes, getDownloadURL  } from "firebase/storage";
import {  FaRegEye } from "react-icons/fa";
import { FcCheckmark } from "react-icons/fc";
import { pdfjs } from "react-pdf";
import axios from 'axios';
import { firebaseApp } from '../firebase/firebaseConfig'
import logo from '../files/MOLO boost logo.png'
import SocialMediaWrapper from "../components/SMWrapper";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;




const app = firebaseApp
const analytics = getAnalytics(app);


const mql = window.matchMedia('(max-width: 480px)');

let mobileView = mql.matches;






export default function SignUpFormFree(props) {
    const [businessName, setBusinessName] = useState("");
    const [placesID, setPlacesID] = useState(null);
    const [businessLogo, setBusinessLogo] = useState(null);
    // const [businessAddress, setBusinessAddress] = useState("");
    const [file, setFile] = useState(null);
    const [fileName1, setFileName1] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const businessTypeRef = useRef();
    const urlRef = useRef();

    const [firebasePaths, setFirebasPaths] = useState([])

    const autoCompleteRef = useRef();
    const businessNameRef = useRef();
    const [showPassword, setShowPassword] = useState(false);

    const [isAnnual, setIsAnnual] = useState(true);


    const options = {
        // componentRestrictions: { country: "us" },
        fields: ["place_id", "icon", "name", "address_components"],
        types: ["establishment"]
    };
    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        businessNameRef.current,
        options
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            setBusinessName(place.name);
            setPlacesID(place.place_id)
            // setBusinessAddress(place.address_components[3].long_name + ', ' +place.address_components[5].long_name)
        });
    }, []);


  const handleBusinessNameChange = (e) => {
    setBusinessName(e.target.value);
  };

  const handleFileChange = (event) => {
    const photo = event.target.files[0];
    setFile(photo);
    setBusinessLogo(photo);
    setFileName1(new Date().getTime() + photo.name)
    }

    const errorCheck = () => {
        setIsLoading(true);

        if(
            firstNameRef.current.value.length > 0 &&
            lastNameRef.current.value.length > 0 &&
            emailRef.current.value.length > 0 &&
            passwordRef.current.value.length > 0 &&
            businessNameRef.current.value.length > 0 &&
            businessTypeRef.current.value.length > 0 &&
            businessLogo
        ){
            setIsLoading(false);
            return true;
        }
        alert('Uh oh, please make sure all fields are filled out properly.')
        setIsLoading(false);
        return false;
    };

    const handleSubmit =  async (event, price_id) => {
        if(errorCheck()){
            setIsLoading(true);
            event.preventDefault();
        
            let imgs;
            const storage = getStorage(app);
            const storageRef = ref(storage, emailRef.current.value + '/' + fileName1);
            setFirebasPaths(firebasePaths.push(emailRef.current.value + '/' + fileName1))
            await uploadBytes(storageRef, businessLogo).then(async (snapshot) => {
            await getDownloadURL(snapshot.ref).then(res =>{imgs=res})
            });

        
            const data = {
                    firstName: firstNameRef.current.value.trim(),   
                    lastName: lastNameRef.current.value.trim(),   
                    email: emailRef.current.value.trim(),   
                    password: passwordRef.current.value.trim(),   
                    businessName: businessNameRef.current.value.trim(),   
                    businessType: businessTypeRef.current.value.trim(),   
                    // businessAddress: businessAddress,
                    pdf: 'textContent',   
                    places_id: placesID,   
                    // price_id: 'price_1Q6egQ05dE8cwj98t8GwF9Fm',
                    price_id: price_id,
                    Images: imgs,
                    IsFreeUser: true,
                    firebasePaths: firebasePaths,
                }
            console.log(data)
            setIsLoading(false);
            const res = axios.post('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + '/api/registerUser', data).then(
                res => {
                console.log(res.status)
                if(res.status == 200){
                    console.log('created user.')
                    window.location = res.data.sessionURL;
                }else if(res.status == 201){
                    console.log('email already in use')
                    alert('Sorry, there is already an account with this email. If youve creat that account, please log in to continue.')
                }else if(res.status == 500){
                    console.log('server error')
                    alert('Sorry, there was a server error. Please try again or contact support info@molostrategies.ca')

                }
                }
            )
        }
    
      }


  return (
    <Box>
        <SocialMediaWrapper>
            <Box w='100%' pt={6} pb={6} > 
                <Flex w={['95%', '60%']} m='0 auto' bg='white' borderRadius={'8px'} border={'solid 3px #eeecea '}>
                    <form style={{ width:'100%'}}>
                        <Stack spacing={4} p={[4, '2rem']}>
                            <Text margin='0 auto'  color={'#6da34d'} textAlign='center' fontSize="2rem" fontWeight="900">Let's get started — fill in your details.</Text>
                            <img src={logo} style={{width:'25%', margin:' 0 auto'}}/>


                            <FormControl isRequired>
                                <FormLabel color='#000' htmlFor="first-name">First Name</FormLabel>
                                <Input id="first-name" type="text"  ref={firstNameRef}  />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel color='#000' htmlFor="last-name">Last Name</FormLabel>
                                <Input id="last-name" type="text" ref={lastNameRef} />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel color='#000' htmlFor="email">Email Address</FormLabel>
                                <Input id="email" type="email"  ref={emailRef} />
                                <FormErrorMessage>Please enter a valid email address</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel color='#000' htmlFor="password">Password</FormLabel>
                                <InputGroup>
                                    <Input id="password" type={showPassword?'':'password'} ref={passwordRef} />
                                    <InputRightAddon 
                                        color='black' 
                                        _hover={{ cursor: 'pointer'}}  
                                        onClick={() => setShowPassword(!showPassword)}  
                                    >
                                        <FaRegEye/>
                                    </InputRightAddon>              
                                </InputGroup>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel color='#000' htmlFor="business-name">Name of Business</FormLabel>
                                <Input  ref={businessNameRef} id="business-name" type="text" value={businessName} onChange={handleBusinessNameChange} />
                                <FormHelperText>Contact info@molostrategies.ca if you do not have a Google My Business listing.</FormHelperText>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel color='#000' htmlFor="business-type">Type of Business</FormLabel>
                                <Input id="business-type" placeholder="Eg. Restaurant, Nail Salon, Barbershop, etc." type="text"  ref={businessTypeRef}/>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel color='#000' htmlFor="photo">Upload a photo of your business's logo</FormLabel>
                                <Input type="file" id="photo" onChange={handleFileChange} accept="image/*"/>
                                <FormHelperText>Only .jpeg and .png files are supported</FormHelperText>
                                {file && <FormHelperText>{file.name}</FormHelperText>}
                            </FormControl>
                        

                            <Box p={1} m={6} mb='8vh' mt='25vh'>
                                        <Flex 
                                            direction={['column', 'column', 'row']} 
                                            justifyContent={'center'} 
                                            alignItems='center'
                                            gap={4}
                                        >
                                            {/* Standard Plan */}
                                            <Flex 
                                                w={['100%', '100%', '33.33%']}
                                                mb={[4, 4, 0]}
                                            > 
                                                <Flex 
                                                    direction={'column'} 
                                                    bg='white' 
                                                    borderRadius={12} 
                                                    p={4}  
                                                    border='2px solid #000' 
                                                    w='100%'
                                                >
                                                    <Text fontSize={'1.4rem'} fontWeight={600}>Standard</Text>
                                                    <Text fontSize={'1rem'} color='#999999'>No contract.</Text>

                                                    <Flex my={3}>
                                                        <Text fontSize={'1.4rem'} fontWeight={600}>$139.99</Text>
                                                        <Flex direction={'column'} mt={1} ml={1}>
                                                            <Text fontSize={'0.6rem'} color='#999999' lineHeight={'120%'}>CAD</Text>
                                                            <Text fontSize={'0.6rem'} color='#999999' lineHeight={'120%'}>/month</Text>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex><Text fontWeight={800}>Unlimited</Text> &nbsp;reviews</Flex>
                                                    <Flex mb={3}><Text fontWeight={800}>Unlimited</Text> &nbsp;contacts</Flex>
                                                    <Text fontWeight={800}>Get these powerful features:</Text>
                                                    <UnorderedList ml={8} spacing={1}>
                                                        <ListItem fontWeight={400}>Collect unlimited Google reviews</ListItem>
                                                        <ListItem fontWeight={400}>Collect unlimited contacts</ListItem>
                                                    </UnorderedList>

                                                    <Button 
                                                        isLoading={isLoading} 
                                                        onClick={(e) => handleSubmit(e,'price_1Q9Ez805dE8cwj98ILidBFCu')} 
                                                        my={6} 
                                                        bg='#5A7BFF' 
                                                        color='white' 
                                                        _hover={{ bg: '#5A7BFF' }} 
                                                        fontWeight={700}
                                                    >
                                                        Get started
                                                    </Button>
                                                </Flex>
                                            </Flex>

                                            {/* Boost Plan */}
                                            <Flex 
                                                w={['100%', '100%', '33.33%']}
                                                mb={[4, 4, 0]}
                                            > 
                                                <Flex 
                                                    direction={'column'} 
                                                    bg='white' 
                                                    borderRadius={12} 
                                                    p={4}  
                                                    border='2px solid #000' 
                                                    w='100%'
                                                >
                                                    <Text fontSize={'1.4rem'} fontWeight={600}>Boost</Text>
                                                    <Text fontSize={'1rem'} color='#999999'>6 month contract.</Text>

                                                    <Flex my={3}>
                                                        <Text fontSize={'1.4rem'} fontWeight={600}>$99.99</Text>
                                                        <Flex direction={'column'} mt={1} ml={1}>
                                                            <Text fontSize={'0.6rem'} color='#999999' lineHeight={'120%'}>CAD</Text>
                                                            <Text fontSize={'0.6rem'} color='#999999' lineHeight={'120%'}>/month</Text>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex><Text fontWeight={800}>Unlimited</Text> &nbsp;reviews</Flex>
                                                    <Flex mb={3}><Text fontWeight={800}>Unlimited</Text> &nbsp;contacts</Flex>
                                                    <Text fontWeight={800}>Get these powerful features:</Text>
                                                    <UnorderedList ml={8} spacing={1}>
                                                        <ListItem fontWeight={400}>Collect unlimited Google reviews</ListItem>
                                                        <ListItem fontWeight={400}>Collect unlimited contacts</ListItem>
                                                    </UnorderedList>

                                                    <Button 
                                                        isLoading={isLoading} 
                                                        onClick={(e) => handleSubmit(e,'price_1Q9Ezn05dE8cwj98c4TjHRbu')} 
                                                        my={6} 
                                                        bg='#5A7BFF' 
                                                        color='white' 
                                                        _hover={{ bg: '#5A7BFF' }} 
                                                        fontWeight={700}
                                                    >
                                                        Get started
                                                    </Button>
                                                </Flex>
                                            </Flex>

                                            {/* Boost+ Plan */}
                                            <Flex 
                                                w={['100%', '100%', '33.33%']}
                                            > 
                                                <Flex 
                                                    direction={'column'} 
                                                    bg='white' 
                                                    borderRadius={12} 
                                                    p={4}  
                                                    border='2px solid #000' 
                                                    w='100%'
                                                >
                                                    <Text fontSize={'1.4rem'} fontWeight={600}>Boost +</Text>
                                                    <Text fontSize={'1rem'} color='#999999'>12 month contract</Text>

                                                    <Flex my={3}>
                                                        <Text fontSize={'1.4rem'} fontWeight={600}>$79.99</Text>
                                                        <Flex direction={'column'} mt={1} ml={1}>
                                                            <Text fontSize={'0.6rem'} color='#999999' lineHeight={'120%'}>CAD</Text>
                                                            <Text fontSize={'0.6rem'} color='#999999' lineHeight={'120%'}>/month</Text>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex><Text fontWeight={800}>Unlimited</Text> &nbsp;reviews</Flex>
                                                    <Flex mb={3}><Text fontWeight={800}>Unlimited</Text> &nbsp;contacts</Flex>
                                                    <Text fontWeight={800}>Get these powerful features:</Text>
                                                    <UnorderedList ml={8} spacing={1}>
                                                        <ListItem fontWeight={400}>Collect unlimited Google reviews</ListItem>
                                                        <ListItem fontWeight={400}>Collect unlimited contacts</ListItem>
                                                    </UnorderedList>

                                                    <Button 
                                                        isLoading={isLoading} 
                                                        onClick={(e) => handleSubmit(e,'price_1Q9F0I05dE8cwj98L6xdl9ET')} 
                                                        my={6} 
                                                        bg='#5A7BFF' 
                                                        color='white' 
                                                        _hover={{ bg: '#5A7BFF' }} 
                                                        fontWeight={700}
                                                    >
                                                        Get started
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Box>
                            
                        </Stack>
                    </form>
                </Flex>
            </Box>
        </SocialMediaWrapper>
    </Box>
    
  )
}
