import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Skeleton,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BiPlusMedical } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DataTableExample from "../components/dataTables";
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';

export default function Contacts() {
  const user = useRecoilValue(userAtom);
  const [tableData1, setListMembers] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);


  const getData = () => {
    axios.get('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/contacts/get/"+user.email).then(res => {
      const members = res.data.members;
      members.forEach((members) => {
        if (members.notes.length > 15) {
          members.notes = members.notes.substring(0, 15) + '...';
        }
    });
    setListMembers(members);
    setDataLoaded(true);
  }).catch(err => {
    console.log(err);
  });
  }


  useEffect(() =>{
    getData();
  }, []) ;


  const columnsData1 = [
    {
      Header: "NAME",
      accessor: "name"
    },
    {
      Header: "PHONE",
      accessor: "phone"
    },
    {
      Header: "NOTES",
      accessor: "notes"
    },
    {
      Header: "DATE ADDED",
      accessor: "dateAdded"
    }
  ];
  
  return (
    <Flex w="100%">

      <Sidebar />
      <Box p={4} w='full' pb={0}>

        <Box w='100%'>
            <Text textAlign={'center'} color='#000' fontSize='2rem' fontWeight='800'>Contact List</Text>
            <Text textAlign={'center'} color='gray.500' fontSize='1rem' fontWeight='500'>
                Manually add, edit, and delete members of your contact list.
            </Text>

            <Box>
                <Flex direction={'column'} m={1} mb='8vh'>
                    <Flex justifyContent={'space-between'} bg='white'>
                        <Box>
                            <Text color='#000' fontSize='1.5rem' fontWeight='500'>Let's grow your list</Text>
                            <Text color='#000' fontSize='1rem' fontWeight='400'>Edit and add to your list to increase conversions and revenue!</Text>
                        </Box>
                        <Link to='/dashboard/contacts/add'>                            
                          <Button bg='#5A7BFF' color='#FFF' _hover={{bg:'#5A7BFF'}}>
                              <BiPlusMedical/>  
                                Manual Add
                          </Button>
                        </Link>

                    </Flex>
                    <Skeleton isLoaded={dataLoaded}>
                      <Box>
                        <DataTableExample
                        tableData={tableData1}
                        columnsData={columnsData1}
                        tableType='CantactList'
                        userEmail={user.email}
                        />
                      </Box>
                    </Skeleton>
                    
                </Flex>
            </Box>
        </Box>
      </Box>
    </Flex>
  )
}