import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Skeleton,
    Image
} from '@chakra-ui/react';

import DataTableExample from "../components/dataTables";
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import axios from 'axios'
import { useState, useEffect } from 'react';

export default function ReviewCard() {
    const user = useRecoilValue(userAtom);
    console.log(user.reviewCardURL);


  return (
    <Flex w="100%">
      <Sidebar />
      <Box p={4} w='full' pb={0}>

        <Box w='100%'>
            <Text textAlign={'center'} color='#000' fontSize='2rem' fontWeight='800'>Digital Review Card</Text>
            <Text textAlign={'center'} color='gray.500' fontSize='1rem' fontWeight='500'>
                Use this Digital Review Card to bring in the reviews while on the go.
            </Text>

            <Box>
                {/* <Image src={'https://firebasestorage.googleapis.com/v0/b/ai-saas-d8377.appspot.com/o/postcard%2F1686948035%2FpostcardDesign.png?alt=media&token=897eda55-e925-4e71-b3ef-546c0fa0f19f'}/> */}
                {/* <Image src={user.reviewCardURL}/> */}
                <Flex h="50vh" m='0 auto' justifyContent={'center'} alignItems={'center'}>
                    <Image h="100%" w="auto" src={user.reviewCardURL} />
                </Flex>
            </Box>
        </Box>
      </Box>
    </Flex>
  )
}