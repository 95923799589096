import { Box, Icon } from '@chakra-ui/react';
import { FaInstagram, FaFacebook } from "react-icons/fa";

const SocialMediaWrapper = ({ children }) => {
  return (
    <Box position="relative" minHeight="100vh">
      {children}
      
      <Box
        position="fixed"
        bottom="20px"
        right="20px"
        display="flex"
        gap="12px"
        zIndex={1000}
      >
        <a 
          href="http://www.instagram.com/moloboost?igsh=d2Nlc3Mxd2I3bDZ1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon
            as={FaInstagram}
            boxSize={6}
            color="#6da34d"
            _hover={{
              color: "#5a8f3d",
              transform: "scale(1.1)",
              transition: "all 0.2s ease-in-out"
            }}
          />
        </a>
        <a 
          href="http://www.facebook.com/share/f7UhTNhG2cAWLWxo/?mibextid=kFxxJD"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon
            as={FaFacebook}
            boxSize={6}
            color="#6da34d"
            _hover={{
              color: "#5a8f3d",
              transform: "scale(1.1)",
              transition: "all 0.2s ease-in-out"
            }}
          />
        </a>
      </Box>
    </Box>
  );
};

export default SocialMediaWrapper;