import { Box, Flex, Text, Button, Input } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import SocialMediaWrapper from '../components/SMWrapper';
import logo from '../files/MOLO boost logo.png'


const ForgotPassword = () => {
  const emailRef = useRef();
  
  const navigate = useNavigate();

  const [isDataLoading, setDataLoading] = useState(false);
  const [err201, seterr201] = useState(false);

  const handleClick = () => {
    setDataLoading(true);

    const auth = getAuth();
    sendPasswordResetEmail(auth, emailRef.current.value.trim())
      .then(() => {
        // Email sent
        seterr201(false);
        // Then redirect to a page telling user to check their email
        // navigate('/check-email');
        alert('Email sent, please check your inbox and spam')
        setDataLoading(false);
      })
      .catch((error) => {
        // Error occurred, could be invalid user email
        seterr201(true);
        setDataLoading(false);
      });
  };

  return (
    <Box>
      <SocialMediaWrapper>
        <Box w={["95%", "30vw"]} margin='0 auto' mt='15vh'>
          <Flex direction='column' color='#000'>
          <img src={logo} style={{width:'50%', margin:' 0 auto'}}/>

            <Text textAlign='center' fontWeight='600' fontSize='1.5rem'>Forgot Password</Text>
            {err201 && 
              <Box p={4} textAlign='center'>
                <Text color='red.300'>Something went wrong. Check your inputs and try again.</Text>  
              </Box>
            }
            
            <Box mb={4}>
              <Text>Email</Text>
              <Input ref={emailRef} />
            </Box>

            <Box>
              <Button 
                isLoading={isDataLoading}
                _hover={{ bg: '#5A7BFF' }} 
                bg="#5A7BFF" 
                w='100%' 
                color='white' 
                fontWeight='500' 
                fontSize='1.1rem'
                onClick={handleClick}
              >
                RESET PASSWORD
              </Button>
            </Box>
          </Flex>
        </Box>
      </SocialMediaWrapper>
    </Box>
  );
};

export default ForgotPassword;
