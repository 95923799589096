import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from './pages/SignUp';
import Verification from './pages/Verification';
import Dashboard from './pages/Dashboard';
import Automations from './pages/Automations';
import Contacts from './pages/Contacts';
import SMSCampaign from './pages/SMSCampaign';
import Campaign from './pages/Campaign';
import QRPage from './pages/QRPage';
import Reviews from './pages/CatchAllReviews';
import Account from './pages/Account';
import ContactAdd from './pages/ContactAdd';
import ContactEdit from './pages/ContactEdit';
import DataCap from "./pages/DataCap";
import TemplateAdd from "./pages/TemplateAdd";
import TemplateEdit from "./pages/TemplateEdit";
import AutomationAdd from "./pages/AutomationAdd";
import AITexts from "./pages/AITexts";
import AITextEdit from "./pages/AITextEdit";
import SignUpForm from "./pages/SignUpForm";
import SignUpFormFree from "./pages/SignUpFormFree";
import ReviewCard from "./pages/ReviewCard";
import ForgotPassword from "./pages/ForgotPassword";
import './index.css'

function App() {

  return (
    <Router>
      <div className="App">
      <Routes>
        <Route path='/' exact element={<Login/>}/>
        <Route path='/login' exact element={<Login/>}/>
        {/* <Route path='/signup' exact element={<SignUp/>}/> */}
        <Route path='/signup/form' exact element={<SignUpForm/>}/>
        <Route path='/signup/free' exact element={<SignUpFormFree/>}/>
        <Route path='/verify' exact element={<Verification/>}/>
        {/* <Route path='/dashboard/home' exact element={<Dashboard/>}/> */}
        <Route path='/dashboard/reviews' exact element={<QRPage/>}/>
        <Route path='/dashboard/feedback' exact element={<Reviews/>}/>
        {/* <Route path='/dashboard/automations' exact element={<Automations/>}/> */}
        <Route path='/dashboard/contacts' exact element={<Contacts/>}/>
        {/* <Route path='/dashboard/artificial-intelligence' exact element={<AITexts/>}/> */}
        {/* <Route path='/dashboard/artificial-intelligence/edit/:id' exact element={<AITextEdit/>}/> */}
        <Route path='/dashboard/CantactList/edit/:id' exact element={<ContactEdit/>}/>
        <Route path='/dashboard/contacts/add' exact element={<ContactAdd/>}/>
        {/* <Route path='/dashboard/templates/add' exact element={<TemplateAdd/>}/> */}
        {/* <Route path='/dashboard/MessageTemplate/edit/:id' exact element={<TemplateEdit/>}/> */}
        {/* <Route path='/dashboard/automation/add' exact element={<AutomationAdd/>}/> */}
        {/* <Route path='/dashboard/sms-campaign' exact element={<SMSCampaign/>}/> */}
        <Route path='/review/:accountID' exact element={<DataCap/>}/>
        <Route path='/dashboard/sms-campaign/:campaignID' exact element={<Campaign/>}/>
        <Route path='/dashboard/review-card' exact element={<ReviewCard/>}/>
        <Route path='/dashboard/account' exact element={<Account/>}/>
        <Route path='/reset' exact element={<ForgotPassword/>}/>
      </Routes>
      </div>
    </Router>
  );
}

export default App;
