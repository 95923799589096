import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Skeleton,
} from '@chakra-ui/react';
import ColumnChart from '../components/ColumnChart';
import { useEffect, useState } from 'react';
import axios from 'axios';
import DataTableExample from "../components/dataTables";
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';


export default function Campaign() {
    const user = useRecoilValue(userAtom);
    const [tableData1, setListMembers] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dataLoaded2, setDataLoaded2] = useState(false);
    const [campaignName, setCampaignName] = useState();
    const [stats, setStats] = useState({});
  
  
    const getData = () => {
      var path = window.location.pathname;
      var parts = path.split('/');
      const name = parts.pop();
      setCampaignName(name);


      const req = axios.get('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/contacts/get/"+user.email).then(res => {
        const members = res.data.members;
        members.forEach((members) => {
          if (members.notes.length > 15) {
            members.notes = members.notes.substring(0, 15) + '...';
          }
      });
      setListMembers(members);
      setDataLoaded(true);
    }).catch(err => {
      console.log(err);
    });


    const req2 = axios.get('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/campaign/get/"+user.email+"/"+name).then(res => {
      console.log(res.data.stats);
      setStats(res.data.stats)
      setDataLoaded2(true);
    }).catch(err => {
      console.log(err);
    });
    }
  
  
    useEffect(() =>{
      getData();
    }, []) ;
  
  
    const columnsData1 = [
      {
        Header: "NAME",
        accessor: "name"
      },
      {
        Header: "PHONE",
        accessor: "phone"
      },
      {
        Header: "NOTES",
        accessor: "notes"
      },
      {
        Header: "DATE ADDED",
        accessor: "dateAdded"
      }
    ];
    

  return (
    <Flex w="100%">
      <Sidebar />
      <Box p={4} w='full' pb={0}>

        <Box w='100%'>
            <Text textAlign={'center'} color='#000' fontSize='2rem' fontWeight='800'>SMS Campaign "{campaignName}"</Text>
            {/* <Text textAlign={'center'} color='gray.600' fontSize='1.4rem' fontWeight='500'>
                ElderlyTuesdays
            </Text> */}
            {/* <Skeleton isLoaded={dataLoaded}>
                <Text textAlign={'center'} color='gray.500' fontSize='1.4rem' fontWeight='500'>
                    Total Recipients: {tableData1.length}
                </Text>
            </Skeleton> */}
            <Box>
                <Flex direction={'column'}  m={1} mb='8vh'>
                    
                    <Box w='100%'  bg='white'>
                        <Box w='50%' m='0 auto'>
                          <Skeleton isLoaded={dataLoaded2}>
                            {dataLoaded2 &&
                              <ColumnChart bg='gray.100' stats={stats}/>
                            }
                          </Skeleton>
                        </Box>
                    </Box>
                    
                    <Box>
                        <Text color='#000' fontSize='1.5rem' fontWeight='500' ml={6}>Recipients</Text>
                        <Skeleton isLoaded={dataLoaded}>
                            <Box>
                              <DataTableExample
                                  tableData={tableData1}
                                  columnsData={columnsData1}
                                  tableType='CantactList'
                                  hideButtons={true}
                                  userEmail={user.email}
                              />
                            </Box>
                        </Skeleton>
                    </Box>

                </Flex>
            </Box>
        </Box>
      </Box>
    </Flex>
  )
}