import LoginComponent from "../components/LoginComponent"
import SocialMediaWrapper from '../components/SMWrapper'

const Login = () => {
  return (
    <div>
      <SocialMediaWrapper>
        <LoginComponent/>
      </SocialMediaWrapper>
    </div>
  )
}

export default Login
