import React, { useState } from 'react';
import Chart from 'react-apexcharts'

const QRChart = (props) => {

    
  return (
    <div className="line" >
        <Chart options=
        {
            {
                chart: {
                  height: 350,
                  type: 'line',
                  dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                  },
                  toolbar: {
                    show: false
                  }
                },
                colors: ['#77B6EA'],
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: 'smooth'
                },
                title: {
                  text: 'QR Scans',
                  align: 'right',
                  color: '#000'
                },
                grid: {
                  borderColor: '#e7e7e7',
                  row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                  },
                },
                markers: {
                  size: 1
                },
                xaxis: {
                  categories: props.dates,
                  title: {
                    text: 'Date'
                  },
                  color: '#000'

                },
                yaxis: {
                  title: {
                    text: 'Scans'
                  },
                  color: '#000'
                },
                legend: {
                  position: 'top',
                  horizontalAlign: 'right',
                  floating: true,
                  offsetY: -25,
                  offsetX: -5
                }
              }
        }
        
        series={[
            {
              name: "QR Scans",
              data: props.quantities
            },
          ]} type="line" width="700" align='center' />
      </div>
  )
}

export default QRChart;
