import { 
    Box, 
    Flex,
    Text,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Button,
    Input,
} from '@chakra-ui/react';

import { FaFlagUsa, FaRegEye } from "react-icons/fa";

const VerifyComponent = () => {
  return (
    <Box>
        <Text textAlign='center'  mt='5vh' mb='2vh' color="#000" fontSize="2rem" fontWeight="900">RetainlySMS</Text>

        <Box w='100%' mt='10vh'>
            <Box p={1} m={'0 auto'} borderRadius="4px" boxShadow={"5px 5px 10px 10px rgba(0,0,0,0.05)"} bg='white' w="30vw">
            <Flex direction='column'>
                    <Flex direction='column' p={6}>
                        <Text textAlign='center' fontSize={'1.4rem'} fontWeight='600' color='#000' mb='10%'>Verification</Text>
                        <Text textAlign='center' fontSize={'1rem'} fontWeight='500' color='gray.500'>
                            We have sent a text containing a 4 digit code. Enter the code below to verify your account.
                        </Text>
                    </Flex>
                    
                    <Flex justifyContent={'space-between'} p={4}>
                        <Input w='22%' border='none' bg='gray.300' borderRadius='0px' textAlign={'center'} value='0'/>
                        <Input w='22%' border='none' bg='gray.300' borderRadius='0px' textAlign={'center'} value='0'/>
                        <Input w='22%' border='none' bg='gray.300' borderRadius='0px' textAlign={'center'} value='0'/>
                        <Input w='22%' border='none' bg='gray.300' borderRadius='0px' textAlign={'center'} value='0'/>
                    </Flex>
                </Flex>
            </Box>

            <Box color="#000" m={'0 auto'}  w="30vw" textAlign={'center'}>
                <Text mt={6}>Didn't get a code? Resend Code</Text>
                <Text>Incorrect phone number? Change number</Text>
            </Box>
        </Box>

        
        
      
    </Box>
  )
}

export default VerifyComponent
