import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyAF4IWez2RYxbu_oa_O6AJchKvHDwi__xA",
  authDomain: "molo-boost.firebaseapp.com",
  projectId: "molo-boost",
  storageBucket: "molo-boost.appspot.com",
  messagingSenderId: "123495207794",
  appId: "1:123495207794:web:fe47795f054d11be8483eb"
};

export const firebaseApp = initializeApp(firebaseConfig);
