import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Avatar,
    Skeleton
} from '@chakra-ui/react';

import {
    FiUsers,
    FiLogOut,
    FiLink,
} from 'react-icons/fi'

import {
    FaMoneyBillWave,
} from 'react-icons/fa'

import {
    CgProfile,
} from 'react-icons/cg'

import {
    MdManageAccounts,
} from 'react-icons/md'

import { 
    BiChevronsRight,
    BiMessageEdit,

} from "react-icons/bi";

import { Link } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilValue, useRecoilState } from 'recoil';
import axios from 'axios';
import { useState } from 'react';

export default function Dashboard() {
    const [user, setUser ] = useRecoilState(userAtom)
    const [isLoaded, setIsLoaded] = useState(true);


    const getPortalURL = () => {
        setIsLoaded(false);
        axios.post('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/stripe/customer-portal", {
          email: user.email
      }).then(res => {
        window.location = res.data.redirect;
        setIsLoaded(true);
      }).catch(err => {
        setIsLoaded(true);
        alert('Uh-oh, there was a server error... try again or contact support.')
      });

    }

  return (
    <Flex w="100%">
      <Sidebar />
      <Box p={4} w='full' pb={0}>

        <Box w='100%'>
            <Text textAlign={'center'} color='#000' fontSize='2rem' fontWeight='800'>Account Details</Text>
            <Text textAlign={'center'} color='gray.500' fontSize='1rem' fontWeight='500'>
                Update your account details or contact customer support (tim@retainlysms.com).
            </Text>

            <Box w='100%' mt={'5vh'} >
                <Flex>
                    <Flex m='0 auto' w='50%' direction={'column'}  justifyContent='space-evenly' borderRadius={'6px'} >
                            {/* <Box w='100%' borderBottom='1px solid rgb(140, 140, 140)' >
                                <Flex justifyContent={'space-between'} _hover={{bg:'gray.100'}} borderRadius="10px">
                                    <Flex>
                                        <CgProfile size='2rem' color='#000' style={{marginRight:'15px'}}/>
                                        <Text color='#000' fontSize={'1.6rem'} fontWeight='700'>My Profile</Text>
                                    </Flex>
                                    <BiChevronsRight color='#000' verticalAlign='bottom' size='2rem'/>
                                </Flex>
                            </Box> */}
                            <Box w='100%' borderBottom='1px solid rgb(140, 140, 140)'>
                                <Skeleton isLoaded={isLoaded}> 
                                    <Flex cursor='pointer' justifyContent={'space-between'} _hover={{bg:'gray.100'}} borderRadius="10px" onClick={() => getPortalURL()}> 
                                        <Flex>
                                            <MdManageAccounts size='2rem' color='#000' style={{marginRight:'15px'}}/>
                                            <Text color='#000' fontSize={'1.6rem'} fontWeight='700'>Account Settings</Text>
                                        </Flex>
                                        <BiChevronsRight color='#000' verticalAlign='bottom' size='2rem'/>
                                    </Flex>
                                </Skeleton>
                            </Box>
                            {/* <Box w='100%' borderBottom='1px solid rgb(140, 140, 140)'>
                                <Flex justifyContent={'space-between'} _hover={{bg:'gray.100'}} borderRadius="10px">
                                    <Flex>
                                        <FaMoneyBillWave size='2rem' color='#000' style={{marginRight:'15px'}}/>
                                        <Text color='#000' fontSize={'1.6rem'} fontWeight='700'>Billing</Text>
                                    </Flex>
                                    <BiChevronsRight color='#000' verticalAlign='bottom' size='2rem'/>
                                </Flex>
                            </Box> */}
                            <Box w='100%' borderBottom='1px solid rgb(140, 140, 140)'>
                                <Link to='/login'>
                                        <Flex justifyContent={'space-between'} _hover={{bg:'gray.100'}} borderRadius="10px">
                                                <Flex>
                                                    <FiLogOut size='2rem' color='#000' style={{marginRight:'15px'}}/>
                                                    <Text color='#000' fontSize={'1.6rem'} fontWeight='700'>Logout</Text>
                                                </Flex>
                                            <BiChevronsRight color='#000' verticalAlign='bottom' size='2rem'/>
                                        </Flex>
                                </Link>
                            </Box>
                    </Flex>
                </Flex>
            </Box>
    
            

        </Box>
        
      </Box>
    </Flex>
  )
}